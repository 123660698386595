@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/theme.scss';

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
    .rtl {
        direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
    display: none;
}

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos.scss';

.ux {
    tab-size: 4;
    -webkit-text-size-adjust: 100%;
    line-height: inherit;
    font-family: Inter,sans-serif;
    --tw-text-opacity: 1;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -.01em;
    --tw-bg-opacity: 0.9;
    list-style: none;
    box-sizing: border-box;
    border: 0 solid #eaeaea;
    --tw-shadow: 0 0 transparent;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(51,140,245,0.5);
    --tw-ring-offset-shadow: 0 0 transparent;
    --tw-ring-shadow: 0 0 transparent;
    color: inherit;
    text-decoration: inherit;
    display: flex;
    align-items: center;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: .75rem;
    padding-right: .75rem;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    outline: none;
}

.anchor {
    scroll-margin-top: 80px
  }